import React, { useEffect, useRef } from 'react'

import { SanityDividerLogoModule } from '@data/sanity/queries/types/modules'
import Logo from '@components/logo'

type DividerLogoProps = Pick<SanityDividerLogoModule, 'logo'>

const DividerLogo = ({ logo }: DividerLogoProps) => {
  const logoRef = useRef<SVGSVGElement>(null)

  useEffect(() => {
    if (!logoRef.current) {
      return
    }

    const logoText = logoRef.current.querySelector(
      '.logo-text'
    ) as HTMLElement | null

    if (!logoText) {
      return
    }

    const handleScroll = () => {
      logoText.style.transform = `rotate(${window.scrollY / 12}deg)`
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <section className="h-0 flex justify-center">
      <Logo
        ref={logoRef}
        type={logo}
        className="text-white absolute z-10 -translate-y-1/2 w-[130px] sm:w-[160px] lg:w-[190px]"
      />
    </section>
  )
}

export default DividerLogo
